.App {
  text-align: center;
  min-height: 100vh;
  background-color: white;
  padding-top: 20px;
}

.App-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  max-width: 240px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

.upload-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 20px auto;
  max-width: 1000px;
  transition: all 0.3s ease;
}

.upload-container.files-loaded {
  transform: scale(0.5);
  margin: -60px auto -80px;
}

.upload-box {
  width: 212px;  /* Reduced by 15% from 250px */
  height: 272px; /* Maintained aspect ratio */
  background: #f8f9fa;
  border: 2px dotted #333;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.upload-instructions {
  color: #007bff;
  font-family: Arial, sans-serif;
  font-size: 1.2em;
  margin: 20px auto;
  width: 840px;
}

.loaded-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;
  font-size: 1.3em;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.tick-mark {
  color: #2ecc71;
  font-size: 2em;
  margin-top: 15px;
}

input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.style-section {
  max-width: 1000px;
  margin: 8px auto;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h3 {
  color: #333;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.style-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: nowrap;
}

.style-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
}

.style-button {
  width: 140px;
  height: 180px;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.style-button.selected {
  transform: scale(1.1) translateY(2px);
  border-color: #007bff;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.style-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.style-option span {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
  font-family: Arial, sans-serif;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button.generating {
  background-color: #ff9800;
  cursor: wait;
}

.submit-button.generated {
  background-color: #4CAF50;
}

.result {
  margin-top: 30px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.download-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  transition: background-color 0.3s;
  font-family: Arial, sans-serif;
}

.download-button:hover {
  background-color: #218838;
}

.drag-drop-zone {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-title {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 10px;
}

.box-title span {
  white-space: nowrap;
}

/* Authentication Styles */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  margin: 0 auto 20px auto;
  width: 100%;
  max-width: 1200px;
  position: relative;
}

.auth-section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}

.logout-button {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #e0e0e0;
}

/* Media Queries */
@media (max-width: 768px) {
  .upload-container {
    flex-direction: column;
    gap: 20px;
  }

  .upload-box {
    width: 180px;
    height: 234px;
  }

  .upload-container.files-loaded {
    transform: scale(0.8);
    margin: -20px auto -30px;
  }

  .style-buttons {
    gap: 10px;
    flex-wrap: wrap;
  }

  .style-option {
    width: 100px;
  }

  .style-button {
    width: 90px;
    height: 120px;
  }

  .top-bar {
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }

  .user-profile {
    font-size: 14px;
  }

  .upload-instructions {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .upload-box {
    width: 153px;
    height: 204px;
  }

  .style-option {
    width: 80px;
  }

  .style-button {
    width: 70px;
    height: 100px;
  }

  .box-title {
    font-size: 1.5em;
  }
}

.preview-section {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.preview-section h4 {
  margin-top: 0;
  color: #666;
  font-style: italic;
}

.download-section {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.download-button.loading {
  background-color: #6c757d;
  cursor: wait;
  position: relative;
  padding-right: 40px;  /* Space for loading spinner */
}

.download-button.loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}